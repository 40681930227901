.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

* {
  box-sizing: border-box;
}
body {
  background: #f5f6f6 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-dark {
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 600px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.25);
  padding: 20px;
  border-radius: 15px;
  transition: all .3s;
}

.container-inner {
  width: 800px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.25);
  padding: 20px;
  border-radius: 15px;
  transition: all .3s;
}

.container-checklists {
  width: 'auto';
  max-width: 1000px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.25);
  padding: 20px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

button.btn.btn-primary.btn-block {
  margin-top: 20px;
}

.columnHeader {
  font-size: 1.2em;
  font-weight: 500;
}

.App {
  height: auto;
}

.formRow {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  height: auto;
  background-color: #ececec;
}

.formText{
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  height: auto;
  background-color: #ececec;
}

.formImages{
  margin-bottom: 10px;
  padding: 10px;
  height: auto;
  background-color: #ececec;
}

.formRow span {
  margin-top: auto; /* Important */ 
  margin-bottom: auto; /* Important */ 
  text-align: left;
}

.images {
  display: flex;
  flex-direction: row;
}